import styled from "styled-components"
import { isMobile } from "react-device-detect"
/*
026833 - green
2E2885 - purple
161112 - darker grey
8986B9 - light purple
C5C4DD - grey
*/
export const Title = styled.h1`
  color: #026833;
  font-size: 1.4em;
  margin: 0;
  text-shadow: 0 0 5px #c5c4dd, 1px 1px 1px #c5c4dd, -1px 1px 1px #c5c4dd,
    1px -1px 1px #c5c4dd, -1px -1px 1px #c5c4dd;
`

export const AppBody = styled.div`
  min-height: 60vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  flex-grow: 1;
`

export const Content = styled.p`
  h1 {
    color: #2e2885;
  }
  p {
    a {
      font-weight: bold;
      color: #2e2885;
      :hover {
        cursor: pointer;
      }
    }
  }
  font-size: ${isMobile ? "1em" : "0.7em"};
  padding: 20px 40px;
  width: 100vw;
  max-width: 950px;
  text-align: left;
  margin-block-start: 2rem;
  margin-block-end: 2rem;
`

export const headerImageStyle = {
  backgroundSize: "cover",
  width: "50%",
  padding: "6%",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  maxHeight: "30vh",
  height: "400px",
  alignItems: "center",
  justifyContent: "center",
}

export const ContentContainer = styled.div`
  max-width: 960px;
  p {
    text-align: justify;
    img {
      width: 70%;
      align-items: center;
      justify-content: center;
    }
  }
  h1 {
    padding-top: 40px;
    font-size: calc(18px + 2vmin);
    text-align: center;
    color: #026833;
  }
  h2 {
    color: #026833;
  }
`
