import React, { useEffect } from "react"
import Paper from "@material-ui/core/Paper"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BkgImg from "gatsby-background-image"
import { AppBody, Title, Content } from "../components/common"

const Page = ({
  data: {
    headerImage,
    logo,
    markdownRemark: { html: __html },
  },
}) => {
  return (
    <Layout>
      <SEO title="Home" />
      <AppBody>
        <div
          className="parallax"
          // fluid={headerImage.childImageSharp.fluid}
          style={{
            backgroundSize: "cover",
            width: "100%",
            padding: "6%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Img
            fluid={logo.childImageSharp.fluid}
            style={{
              alignItems: "center",
              width: "50%",
              minWidth: "400px",
              alignSelf: "center",
            }}
            alt="plains dedicated express"
          />
          <Title></Title>
          <div>
            <Button
              onClick={() => {
                window.open(
                  "https://plains-dedicated-express.s3-us-west-1.amazonaws.com/Driver's+Application+for+Employment+JJ.pdf"
                )
              }}
            >
              <span>Join Our Team</span>
            </Button>
          </div>
        </div>
        <Content>
          <p style={{ textAlign: "center", paddingTop: "1em" }}>
            You can be one of the few to join Plains Express after qualifying
            and a referral process. We pick those that pride themselves on
            working in a family environment where everyone makes a honest
            living, and on delivering quality to customers on all levels of
            safety, service, and profits.
          </p>
          <Line />
          <h1>Owner-Operator</h1>
          <p>
            Leasing your truck with Plains Dedicated Express allows you to
            benefit from our unique compensation system that is based on the age
            of your equipment. Below are the current compensation levels:
          </p>
          <blockquote>
            <p>Level I – 4 years and under</p>
            <p>Level II – 5 years and older</p>
          </blockquote>
          <p>
            Gross revenue is calculated as a percentage based on your specific
            equipment Level:
          </p>
          <blockquote>
            <p>Level I Tractor = 77% </p>
            <p>Level I Trailer = 11%</p>
            <p>Level II Tractor = 75%</p>
            <p>Level II Trailer = 10%</p>
          </blockquote>
          <p>
            As an owner operator you could have a combination of equipment in
            two different Levels
          </p>
          <p>
            For example: A Level I tractor with a Level II trailer would result
            in an <strong>87%</strong> of the line haul, stop pay, detention and
            layover revenue.
          </p>
          <p>
            Plains Dedicated Express pays <strong>100%</strong> of the fuel
            surcharge as long as the truck and trailer are leased to us.
          </p>
          <p>
            Owner operators will be required to keep an escrow account. Plains
            Dedicated Express has various methods available to assist in the
            funding of the escrow account.
          </p>
          <p>Plains Dedicated Express offers weekly settlement processing.</p>
          <p>
            To help grow your fleet or to replace your current equipment, Plains
            Dedicated Express offers a competitive lease to purchase program.
            Owner operators meeting the minimum requirements are encouraged to
            apply and join the Plains Dedicated Express team. Should you meet
            the minimum requirements, please <a href="#contact">contact us!</a>
          </p>
          <Line />
          <h1>Minimum requirements:</h1>
          <ul>
            <li>Minimum age of 23 years</li>
            <li>
              1 -2 year(s) of current over the road diesel tractor and trailer
              driving experience
            </li>
            <li>Driving experience with refrigerated trailers is preferred</li>
            <li>
              Meet all DOT regulations pertaining to driver qualifications
            </li>
            <li>
              Must pass all DOT required physical exams and drug and alcohol
              tests
            </li>
            <li>Must be insurable and be approved by our insurance carrier</li>
            <li>
              Drivers 25 or older may have no more than 3 moving violations or
              one accident and one violation in the last 3 years
            </li>
            <li>
              Drivers 23 &#x26; 24 can have no more than 2 moving violations in
              the last 3 years
            </li>
            <li>Drivers must meet criminal record policy requirements</li>
          </ul>
          <Line />
          <h1 id="contact">Contact us</h1>
          <p>
            To apply for a position, complete the Driver Application and email
            to{" "}
            <a href="mailto:careers@plainsdedicated.com">
              careers@plainsdedicated.com
            </a>
          </p>
          <p>
            To reach dispatch email{" "}
            <a href="mailto:ExpressDispatch@plainsdedicated.com">
              ExpressDispatch@plainsdedicated.com
            </a>
          </p>
          <p>
            To reach the safety department for any safety related issues or
            complaints email them at{" "}
            <a href="mailto:safety@plainsdedicated.com">
              safety@plainsdedicated.com
            </a>
          </p>
        </Content>
        <Cards>
          <Paper
            style={{
              textAlign: "center",
              alignItems: "left",
              margin: "10px",
              padding: "10px",
              width: "400px",
              maxWidth: "90vw",
              minWidth: "30vw",
            }}
          >
            <PaperHeader>Corporate Office</PaperHeader>
            <PaperText>
              8390 Champions Gate Blvd Suite 215 <br />
              Championsgate, FL 33896
              <br />
              (720) 259-7140
              <br />
              info@plainsdedicated.com
            </PaperText>
          </Paper>
          {/* <Paper
            style={{
              textAlign: "center",
              alignItems: "left",
              margin: "10px",
              padding: "10px",
              width: "400px",
              maxWidth: "90vw",
              minWidth: "30vw",
            }}
          >
            <PaperHeader>Missouri Terminal</PaperHeader>
            <PaperText>
              201 South 1st Street
              <br />
              Sheridan, MO 64486
              <br />
              (660) 799-2226 Main
              <br />
              info@plainsdedicated.com
            </PaperText>
          </Paper> */}
        </Cards>
      </AppBody>
    </Layout>
  )
}

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logos/pde.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "truck1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: "/" } }) {
      html
    }
  }
`

export default Page

const PaperHeader = styled.h3`
  font-size: 1.2em;
  margin-block-start: 2rem;
  margin-block-end: 2rem;
  color: #2e2885;
`

const PaperText = styled.p`
  font-size: 0.7em;
`

const Line = styled.hr`
  background-color: #026833;
  height: 3px;
  width: 60%;
  text-align: center;
  margin: 0 auto;
  margin-top: 3em;
  margin-bottom: 3em;
`

const Button = styled.button`
  border-radius: 10px;
  background-color: #026833;
  border: none;
  margin-top: 30px;
  padding: calc(10px + 0.5vh) calc(15px + 2vw);
  span {
    color: white;
    font-size: calc(10px + 2vw);
    font-weight: bold;
  }
  :hover {
    cursor: pointer;
    background-color: #29915b;
  }
  :focus {
    background-color: #004521;
    span {
      color: #a8a8a8;
    }
  }
`

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1vh 5vw;
  width: 100vw;
`
